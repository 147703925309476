import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { AosClasses }  from '../../AosStyle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

function Network (props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component='h1' variant='h5'>
          Network
        </Typography>
      </Grid>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={3} md={2}>
          <Typography>IP address</Typography>
        </Grid>
        <Grid>
          <Typography><strong>{props.address}</strong></Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={3} md={2}>
          <Typography>Netmask</Typography>
        </Grid>
        <Grid item>
          <Typography><strong>{props.netmask}</strong></Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={3} md={2}>
          <Typography>DNS</Typography>
        </Grid>
        <Grid item>
          <Typography><strong>{props.dns}</strong></Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={12} sm={3} md={2}>
          <Typography><strong>Interface status</strong></Typography>
        </Grid>
        <Grid item>
          <Typography>{props.interfaceStatus}</Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={12} sm={3} md={2}>
          <Typography><strong>Network status</strong></Typography>
        </Grid>
        <Grid item>
          <Typography>{props.networkStatus}</Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={12} sm={3} md={2}>
          <Typography><strong>DNS status</strong></Typography>
        </Grid>
        <Grid item>
          <Typography>{props.dnsStatus}</Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={12} sm={3} md={2}>
          <Typography><strong>Internet status</strong></Typography>
        </Grid>
        <Grid item>
          <Typography>{props.internetStatus}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(AosClasses)(Network);
