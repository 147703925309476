import createStore from "redux-zero";
import { tempLoginStates, loginStates } from "../components/Defines.js";

const initialState = {
                        connectDeviceIP: '',
                        mqttConnected: false,
                        mqttClient: null,
                        adminClient: null,
                        O2Timestamps: [],
                        oxygenData: [],
                        tempTimestamps: [],
                        temperatureData:[],
                        connectedProbeReader: null,
                        probeReaderName: '',
                        password: '',
                        passwordExpiry: 0,
                        tempLoginState: tempLoginStates.INITIAL,
                        loginState: loginStates.NOT_LOGGED_IN,
                        manualCredentials:{
                          username: "",
                          password: "",
                        },
                        authConnectionMessage: "",
                        adminClientId: "",
                        temperatureReadout: 0.0,
                        oxygenReadout: 0.0,
                        timeMarkers: [],
                        deviceTime: undefined,
                        users: [],
                    };
const store = createStore(initialState);

export default store;
