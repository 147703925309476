import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { AosClasses } from "../../AosStyle";
import { withStyles } from "@material-ui/core/styles";

export function Storage(props) {
  const handleClick = event => {
    props.publishConfigValue("loggingResolution", event.currentTarget.value);
  };

  const { classes } = props;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography component="h1" variant="h4">
          Storage
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          <strong>Storage: </strong>
          {props.readerConfig.storageState} <br />
          <strong>Status: </strong>
          {props.readerConfig.loggingState}
        </Typography>
      </Grid>
      <Grid item>
        <Typography component="h2" variant="h5">
          Storage Space
        </Typography>
        <LinearProgress
          variant="determinate"
          value={
            (100 * props.readerConfig.storageUsed) /
            props.readerConfig.storageSize
          }
        />
        <Typography>
          {props.readerConfig.storageUsed} GB used /{" "}
          {props.readerConfig.storageSize} GB available
        </Typography>
      </Grid>
      <Grid item>
        <Typography component="h2" variant="h5">
          Logging Resolution
        </Typography>
        <RadioGroup>
          {
            [1, 2 , 4].map((sps) => (
              <FormControlLabel
                key={sps}
                label={`${sps} SPS`}
                disabled={props.readerConfig.loggingState === 'logging'}
                control={
                  <Radio
                    value={sps}
                    checked={props.readerConfig.loggingResolution === sps}
                    classes={{ root: classes.radio, checked: classes.checked }}
                    onChange={handleClick}
                  />
                }
              />
            ))
          }
        </RadioGroup>
      </Grid>
    </Grid>
  );
}

Storage.propTypes = {
  storageState: PropTypes.string,
  loggingState: PropTypes.string,
  storageUsed: PropTypes.number,
  storageSize: PropTypes.number,
  loggingResolution: PropTypes.number
};

export default withStyles(AosClasses)(Storage);
