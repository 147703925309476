import React from "react";
import mqtt from "mqtt";
import { connect } from "redux-zero/react";
import actions from "../../store/actions";
import moment from "moment";
import worker from "../../app.worker.js";
import WebWorker from "../../WebWorker.js";
import { get, set } from "idb-keyval";
import { tempLoginStates, loginStates } from "../Defines.js";

const mapToProps = ({
  mqttConnected,
  mqttClient,
  connectDeviceIP,
  O2Timestamps,
  oxygenData,
  tempTimestamps,
  temperatureData,
  password,
  passwordExpiry,
  connectedProbeReader,
  tempLoginState,
  loginState,
  manualCredentials,
  adminClient
}) => ({
  mqttConnected,
  mqttClient,
  connectDeviceIP,
  O2Timestamps,
  oxygenData,
  tempTimestamps,
  temperatureData,
  password,
  passwordExpiry,
  connectedProbeReader,
  tempLoginState,
  loginState,
  manualCredentials,
  adminClient
});

class Mqtt extends React.Component {
  handleNewMessage = payload => {
    const newMsg = JSON.parse(payload.toString());

    var convertedTimestamps = [];
    newMsg["time-points"].forEach(time_element => {
      const time = moment(time_element).format("YYYY-MM-DD HH:mm:ss.SS");
      convertedTimestamps.push(time);
    });
    this.props.setDeviceTime(
      moment.utc(newMsg["time-points"][newMsg["time-points"].length - 1])
    );

    var messageDict = {
      oxygenTime: this.props.O2Timestamps,
      oxygenData: this.props.oxygenData,
      tempTime: this.props.tempTimestamps,
      tempData: this.props.temperatureData,
      signal: newMsg["signal"],
      span: newMsg["seconds-span"],
      epochTimestamps: newMsg["time-points"],
      timestamps: convertedTimestamps,
      values: newMsg["values"],
      logging: this.props.connectedProbeReader.logging === "logging"
    };

    return messageDict;
  };

  handleMessage = (topic, payload) => {
    //Handle the config message
    if (RegExp("[0-9A-z]+/configuration").test(topic)) {
      const newMsg = JSON.parse(payload.toString());
      this.props.connectProbeReader(newMsg);
      this.setState({ connectingText: "Received device config" });
      var clientID = newMsg["id"];
      if (!this.historyReceived) {
        console.log("config message received, publishing history request");
        this.client.publish(`${this.clientId}/${clientID}/request/history`, ""); //Publish to the history topic
        this.historyReceived = true; //Handle the history message;
      }
    } else if (RegExp("[0-9A-z]+/history").test(topic)) {
      const newMsg = JSON.parse(payload.toString());
      this.setState({ connectingText: "Received history message" });

      if (newMsg.signal === "markers") {
        var arr = [];
        newMsg.markers.forEach(marker => {
          arr.push(moment(marker));
        });
        this.props.addTimeMarkers(arr);
      } else {
        var convertedTimestamps = [];
        newMsg["time-points"].forEach(time_element => {
          const time = moment(time_element).format("YYYY-MM-DD HH:mm:ss.SS");
          convertedTimestamps.push(time);
        });
        this.setState({ connectingText: "Finished converting timestamps" });

        if (newMsg["signal"] === "oxygen") {
          this.setState({ connectingText: "Received oxygen data" });
          this.props.updateOxygenGraphData(
            convertedTimestamps,
            newMsg["values"]
          );
          this.receivedOxygen = true;
        } else if (newMsg["signal"] === "temperature") {
          this.setState({ connectingText: "Received temperature data" });
          this.props.updateTemperatureGraphData(
            convertedTimestamps,
            newMsg["values"]
          );
          this.receivedTemp = true;
        }

        // Wait until all of the historical data has been received
        if (this.receivedOxygen && this.receivedTemp) {
          console.log("received history data for oxygen and temperature");
          this.setState({ connectingText: "Received history" });
          this.setState({ connecting: false });
          this.props.connectMQTT();
          this.client.unsubscribe("+/history");
        }
      }
    } else if (RegExp("[0-9A-z]+/data/marker").test(topic)) {
      let marker = moment(JSON.parse(payload));
      this.props.addTimeMarkers([marker]);
    } else if (RegExp("[0-9A-z]+/data/tick").test(topic)) {
      let dataTick = JSON.parse(payload.toString());
      let messageDict = {
        oxygenTime: this.props.O2Timestamps,
        oxygenData: this.props.oxygenData,
        tempTime: this.props.tempTimestamps,
        tempData: this.props.temperatureData,
        signal: "",
        span: 1,
        epochTimestamps: [dataTick.timestamp],
        values: []
      };
      for (var index = 0; index < 3; index++) {
        messageDict.signal = dataTick.values[index].signal;
        messageDict.values = [dataTick.values[index].value];
        this.worker.postMessage(messageDict);
      }
      this.props.setDeviceTime(moment.utc(dataTick.timestamp));
    } else if (RegExp("[0-9A-z]+/data/.+").test(topic)) {
      var messageDict = this.handleNewMessage(payload);
      this.worker.postMessage(messageDict);
    } else if (RegExp("auth").test(topic)) {
      let authObject = JSON.parse(payload.toString());
      if (authObject.expiry === "denied") {
        this.props.tempLoginDenied();
      } else {
        this.props.tempLoginApproved(authObject.expiry);
        set("password", this.props.password);
        set("expiry", authObject.expiry);
      }
    } else if (RegExp("[0-9A-z]+/users").test(topic)) {
      var userList = JSON.parse(payload.toString());
      this.props.setUsers(userList.list);
    }
  };

  handleConnect = () => {
    console.log("MQTT.handleConnect");
    this.props.mqttClientConnect(this.client);
    while (!this.props.mqttClient) {}
    this.receivedTemp = false;
    this.receivedOxygen = false;
    this.historyReceived = false;
    this.client.subscribe("+/configuration"); // Config topic
    this.client.subscribe("+/history"); // History topic
    this.client.subscribe("+/data/#");
    this.client.subscribe(`+/${this.clientId}/auth`);
    this.client.on("message", this.handleMessage);
  };

  handleAuthConnect = () => {
    console.log("MQTT#handleAuthConnect()");
    this.props.setAdminClient(this.adminClient);
    this.props.loginSuccess();
    this.adminClient.subscribe("+/users");
    this.adminClient.on("message", this.handleMessage);
  };

  handleError = err => {
    if (
      RegExp(
        'Error: "?Connection refused: Not authorized"?' +
          '|Error: "?Connection refused: Bad username or password"?'
      ).test(err)
    ) {
      console.log(err);
      this.client.end(false, {}, this.endConnection);
    } else {
      console.log("Could not connect to your probe reader.");
      console.log(err);
      this.client.end(false, {}, this.endConnection);
    }
  };

  handleAuthError = err => {
    console.log(`Mqtt#handleAuthError. ${err}`);
    if (RegExp('Error: "?Connection refused: Not authorized"?').test(err)) {
      this.adminClient.end(false, {}, this.props.logoutSuccess());
      this.props.setAuthConnectioMessage("Connection refused: not authorized.");
    } else if (
      RegExp('Error: "?Connection refused: Bad username or password"?').test(
        err
      )
    ) {
      console.log(err);
      this.adminClient.end(false, {}, this.props.logoutSuccess());
      this.props.setAuthConnectioMessage(
        "Connection refused: bad username or password."
      );
    } else {
      console.log("Could not connect to your probe reader.");
      console.log(err);
      this.adminClient.end(false, {}, this.props.logoutSuccess());
    }
    this.props.setAdminClientId("");
  };

  handleOffline = err => {
    console.log("Device is offline, connection failed");
    console.log(err);
    this.client.end(false, {}, this.endConnection);
    this.props.disconnectMQTT();
    this.props.mqttClientDisconnect();
  };

  handleAuthOffline = err => {
    console.log("Mqtt#handleAuthOffline");
    console.log(err);
    this.client.end(false, {}, this.props.logoutSuccess());
    this.props.setAdminClientId("");
    this.props.logoutSuccess();
  };

  startConnection = () => {
    console.log("mqtt.startConnection");
    get("mqttClientId")
      .then(val => {
        if (typeof val === "undefined") {
          val =
            "AosWebClient_" +
            Math.random()
              .toString(16)
              .substr(2, 8);
          set("mqttClientId", val);
        }
        return val;
      })
      .then(clientId => {
        console.log(clientId);
        this.clientId = clientId;
        var options = {
          clientId: this.clientId
        };
        this.client = mqtt.connect(
          `ws://${this.props.connectDeviceIP}:9004`,
          options
        );

        if (this.client) {
          this.client.on("connect", this.handleConnect);

          this.client.on("error", this.handleError);

          this.client.on("offline", this.handleOffline);
        }
      });
  };

  startAuthConnection = (username, password) => {
    var options = {
      clientId: this.clientId + "_auth",
      username: username,
      password: password
    };
    this.adminClient = mqtt.connect(
      `ws://${this.props.connectDeviceIP}:9005`,
      options
    );

    if (this.adminClient) {
      this.adminClient.on("connect", this.handleAuthConnect);

      this.adminClient.on("error", this.handleAuthError);

      this.adminClient.on("offline", this.handleAuthOffline);

      this.props.setAdminClientId(options.clientId);
    }
  };

  endAuthConnection = () => {
    const disconnectedCb = () => {
      console.log(
        `adminclient disconnect callback. connected: ${this.props.adminClient.connected}`
      );
      this.props.logoutSuccess();
    };

    console.log("Mqtt#endAuthConnection()");
    if (this.props.adminClient) {
      this.props.adminClient.end(false, {}, disconnectedCb);
      console.log(
        `Mqtt#endAuthConnection(). adminClient connected: ${this.props.adminClient.connected} `
      );
    }
  };

  endConnection = () => {
    if (this.props.adminClient !== null && this.props.adminClient.connected) {
      this.endAuthConnection();
    }
    if (this.props.mqttClient) {
      this.props.mqttClient.end();
      this.props.disconnectMQTT();
      this.props.mqttClientDisconnect();
    }
    // this.worker.terminate();
  };

  unsubscribeFromDatastream() {
    if (this.props.mqttClient) {
      this.props.mqttClient.unsubscribe("+/data/#");
    }
    this.worker.terminate();
  }

  componentDidMount() {
    this.worker = new WebWorker(worker);

    this.worker.addEventListener("message", event => {
      if (event.data.dataType === "oxygen") {
        this.props.updateOxygenGraphData(
          event.data.O2Timestamps,
          event.data.oxygen
        );
      } else if (event.data.dataType === "temperature") {
        this.props.updateTemperatureGraphData(
          event.data.tempTimestamps,
          event.data.temperature
        );
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.connectDeviceIP === "" && this.props.connectDeviceIP !== "") {
      this.startConnection();
    } else if (
      prevProps.connectDeviceIP !== "" &&
      this.props.connectDeviceIP === ""
    ) {
      this.endConnection();
    }

    if (
      prevProps.tempLoginState !== this.props.tempLoginState &&
      typeof this.props.tempLoginState !== "undefined"
    ) {
      console.log(
        `MQTT. Change in tempLoginState. tempLoginState: ${this.props.tempLoginState}`
      );
      switch (this.props.tempLoginState) {
        case tempLoginStates.TEMP_PENDING:
          var message = {
            id: this.clientId,
            password: this.props.password,
            expiry: this.props.passwordExpiry
          };
          console.log(this.client);
          this.client.publish(
            `${this.clientId}/${this.props.connectedProbeReader.id}/request/auth`,
            JSON.stringify(message)
          );
          break;
        default:
          break;
      }
    }

    if (
      prevProps.loginState !== this.props.loginState &&
      this.props.loginState === loginStates.LOGIN_PENDING
    ) {
      console.log(`Mqtt#componentDidUpdate(), ${this.props.loginState}`);
      this.startAuthConnection(
        this.props.manualCredentials.username,
        this.props.manualCredentials.password
      );
    } else if (
      prevProps.loginState !== this.props.loginState &&
      this.props.loginState === loginStates.LOGOUT_PENDING
    ) {
      console.log(`Mqtt#componentDidUpdate(), ${this.props.loginState}`);
      this.endAuthConnection();
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export default connect(mapToProps, actions)(Mqtt);
