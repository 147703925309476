import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import NewProbeReaderDialog from './NewProbeReaderDialog.js';
import DeviceCard from "./DeviceCard.js";


class DeviceCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      probeReaders: null,
      modalOpen: false
    };
  }

  handleModalOpen = () => {
    this.setState({modalOpen: true});
  }

  handleModalClose = value => {
    this.setState({modalOpen: false});
  };

  componentDidUpdate(prevProps) {
  }

  render = () => {
		return (
      <Container maxWidth="xl" className={this.props.classes.root}>
        {(typeof(this.props.probeReaders) !== "undefined" && this.props.probeReaders !== null && this.props.probeReaders.length > 0) ?
          <React.Fragment>
            <Typography variant="h5">Previously Connected Devices</Typography>
            <Grid
              container
              justify="center"
              className={this.props.classes.root}
              spacing={2}
            >

              {this.props.probeReaders.map(objects => (
                <Grid
                  item
                  key={
                      objects.name + "_" + objects.url + "_" + objects.port
                  }
                  md={4}
                  xs={12}
                >
                  <DeviceCard
                    name={objects.name}
                    ip={objects.url}
                    port={objects.port}
                    styleClasses={this.props.classes}
                    removeConnection={this.props.removeConnection}
                    startConnection={this.props.startConnection}
                  />
                </Grid>
              ))}
            </Grid>
            <br />
            <br />
          </React.Fragment>
        :
        <Typography>You have no saved probe readers. Use the + button in the bottom right hand corner to add one.</Typography>
        }
        <NewProbeReaderDialog open={this.state.modalOpen} onClose={this.handleModalClose} saveConnectionToDatabase={this.props.saveConnectionToDatabase}/>
        <Fab color="primary" aria-label="add" className={this.props.classes.fab} onClick={this.handleModalOpen}>
              <AddIcon />
            </Fab>
      </Container>
    );
  }
}

export default DeviceCollection;
