import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { AosClasses } from '../../AosStyle';
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import moment from 'moment';
import AutomaticTimeConfig from './AutomaticTimeConfig';
import ManualTimeConfig from './ManualTimeConfig';

function DateTime (props) {

  const { classes } = props;

  const handleTimeConfigClick = (event) => {
    console.log(`DateTime#handleTimeConfigClick(). value: ${event.currentTarget.value}`);
    props.publishConfigValue("timeConfiguration", event.currentTarget.value);
  }

  var clockTime = moment.parseZone(props.clockTime);
  clockTime = clockTime.add(props.readerConfig.hoursOffset, 'hours');
  if(props.readerConfig.hoursOffset >= 0){
    clockTime = clockTime.add(props.readerConfig.minutesOffset, 'minutes');
  } else {
    clockTime = clockTime.add(-props.readerConfig.minutesOffset, 'minutes');
  }

  // console.log(props.clockTime.format("YYYY-MM-DD HH:mm:ss ZZ"));
  // console.log(clockTime.format("YYYY-MM-DD HH:mm:ss ZZ"));
  // console.log(props.readerConfig.hoursOffset);
  return (
    <Grid container spacing={2} direction='row'>
      <Grid item xs={12}>
        <Typography component='h1' variant='h5'>
          Date/Time
        </Typography>
      </Grid>
      <Grid item container xs={12} direction='row' alignItems='baseline' spacing={1}>
        <Grid item>
          <Typography>Current time</Typography>
        </Grid>
        <Grid item>
          <Typography>
            <strong>
              {clockTime.format("YYYY-MM-DD HH:mm:ss")}
            </strong>
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} direction='row' spacing={1} alignItems='center'>
        <Grid item>
          <Typography>Configuration</Typography>
        </Grid>
        <Grid item container direction='row' xs={12} sm={3}>
          <Grid item>
            <FormControlLabel
              label='Automatic'
              disabled={props.readerConfig.loggingState === 'logging'}
              control={
                <Radio
                  value='Automatic'
                  checked={props.readerConfig.timeConfiguration === 'Automatic'}
                  classes={{root:classes.radio, checked: classes.checked}}
                  onChange={handleTimeConfigClick}
                />
              }
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              label='Manual'
              disabled={props.readerConfig.loggingState === 'logging'}
              control={
                <Radio
                  value='Manual'
                  checked={props.readerConfig.timeConfiguration === 'Manual'}
                  classes={{root:classes.radio, checked: classes.checked}}
                  onChange={handleTimeConfigClick}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      {props.readerConfig.timeConfiguration === 'Automatic' ? (
        <AutomaticTimeConfig
          ntpStatus={props.readerConfig.ntpStatus}
          hoursOffset={props.readerConfig.hoursOffset}
          minutesOffset={props.readerConfig.minutesOffset}
          publishConfigValue={props.publishConfigValue}
          loggingState={props.readerConfig.loggingState === 'logging'}
        />
      ) : (
        <ManualTimeConfig
          manualDateTime={clockTime}
          publishConfigValue={props.publishConfigValue}
          loggingState={props.readerConfig.loggingState === 'logging'}
        />
      )}
    </Grid>
  );
}


export default withStyles(AosClasses)(DateTime);
