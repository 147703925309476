import React, {useState, useEffect} from "react";
import TemporaryPermissionLogin from "./TemporaryPermissionLogin";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import LoginForm from "./LoginForm.js";
import ConfigLogout from "./ConfigLogout.js";
import {loginStates} from "../Defines.js";
import { AosClasses } from '../../AosStyle';
import { withStyles } from '@material-ui/core/styles';

export function ConfigLogin(props) {

  const { classes } = props;
  const[loginType, setRadioValue] = useState("permanent");
  const[connectionMessage, setMessage] = useState(props.connectionMessage);

  useEffect(() => {
    setMessage(props.connectionMessage);
  }, [props.connectionMessage]);

  const handleRadioChange = event => {
    setRadioValue(event.target.value);
    setMessage("");
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography component="h5" variant="h5">Admin sign in</Typography>
      </Grid>
      {(props.loginState === loginStates.LOGGED_IN) ?
        (
          <Grid item>
            <ConfigLogout logout={props.logout}/>
          </Grid>
        ) :
        (
          <Grid container spacing={4}>
            <Grid container item spacing={1} xs={12} md={6} direction="row" alignItems="center">
              <Grid item xs={2}>
                <Radio
                  onChange={handleRadioChange}
                  checked={loginType === "permanent"}
                  value="permanent"
                  classes={{root: classes.radio, checked: classes.checked}}
                />
              </Grid>
              <Grid item xs={10}>
                <LoginForm
                  login={props.login}
                  disabled={loginType === "temporary"}
                  connectionMessage={connectionMessage}
                  loginState={props.loginState}
                  classes={props.classes}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={1} xs={12} md={6} direction="row" alignItems="center">
              <Grid item xs={2}>
                <Radio
                  onChange={handleRadioChange}
                  checked={loginType === "temporary"}
                  value="temporary"
                  classes={{root: classes.radio, checked: classes.checked}}
                />
              </Grid>
              <Grid item xs={10}>
                <TemporaryPermissionLogin
                  requestAdminPermission={props.requestAdminPermission}
                  tempLogin={props.tempLogin}
                  forgetBrowserLogin={props.forgetBrowserLogin}
                  tempLoginState={props.tempLoginState}
                  loginState={props.loginState}
                  disabled={loginType === "permanent"}
                  classes={props.classes}
                  connectionMessage={connectionMessage}
                />
              </Grid>
            </Grid>
          </Grid>)
      }
    </Grid>
  )
}

export default withStyles(AosClasses)(ConfigLogin);
