export const tempLoginStates = {
  TEMP_NO_AUTH: "tempNoAuth",
  TEMP_PENDING: "tempPending",
  TEMP_AUTHED: "tempAuthed",
  INITIAL: "initial"
}

export const loginStates = {
  LOGOUT_PENDING: "logoutPending",
  NOT_LOGGED_IN: "notLoggedIn",
  LOGIN_PENDING: "loginPending",
  LOGGED_IN: "loggedIn"
}
