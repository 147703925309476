import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { AosClasses } from "../../AosStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import moment from "moment";
import NewUserDialog from "./NewUserDialog";

function Users(props) {
  const { publishConfigValue } = props;

  const handleDelete = username => () => {
    props.publishConfigValue("delete-user", username);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h5">
          Users
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <NewUserDialog publishConfigValue={publishConfigValue} />
      </Grid>
      <Grid item>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>expiry</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.users.map(user => (
              <TableRow key={user.username}>
                <TableCell>{user.username}</TableCell>
                <TableCell>
                  {user.expiry !== "undefined"
                    ? moment
                        .utc(user.expiry)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss Z")
                    : ""}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={handleDelete(user.username)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}

export default withStyles(AosClasses)(Users);
