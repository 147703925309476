import React from 'react';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles';
import { AosClasses } from '../../AosStyle';
import Box from '@material-ui/core/Box';

export function ConfigList (props) {

const reader = props.connectedProbeReader;

  return (
    <Container maxWidth="xl" className={props.classes.root}>
      <Typography component="h1" variant='h4'>Configuration</Typography>
      <br />
      <Grid container>
        {
          [
          [
          {fieldName: 'Thermocouple type',
          value: reader.thermocoupleType},
          {fieldName: 'Gas configuration',
          value: reader.gasConfiguration},
          {fieldName: 'Reducing atmosphere sign',
          value: reader.reducingPolarity}
          ],
          [
          {fieldName: 'Logging state',
          value: reader.loggingState},
          {fieldName: 'Logging resolution',
          value: reader.loggingResolution},
          {fieldName: 'Storage state',
          value: reader.storageState},
          {fieldName: 'Storage size',
          value: `${reader.storageSize} GB`},
          {fieldName: 'Storage used',
          value: `${reader.storageUsed} GB`}
          ],
          [
          {fieldName: 'Time configuration',
          value: reader.timeConfiguration}
          ],
          [
          {fieldName: 'Network address',
          value: reader.address},
          {fieldName: 'Interface status',
          value: reader.interfaceStatus},
          {fieldName: 'Internet status',
          value: reader.internetStatus}
          ],
          [
          {fieldName: 'Device ID',
          value: reader.deviceId},
          {fieldName: 'Firmware version',
          value: reader.firmwareVersion}
          ]
          ].map(array => (
            array.map(tuple => (
              <React.Fragment key={tuple.fieldName}>
                <Grid item xs={12} md={4}>
                  <Typography>{tuple.fieldName}</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography component='div'>
                    <Box fontWeight="fontWeightBold">
                      {tuple.value}
                    </Box>
                  </Typography>
                </Grid>
              </React.Fragment>
            ))
          ))
        }
      </Grid>
    </Container>
  );
}

export default withStyles(AosClasses)(ConfigList);
