import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { AosClasses } from "../../AosStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

function OxygenConfig(props) {

  const { classes } = props;

  const handleGasClick = (event) => {
    console.log(`OxygenConfig#handleGasClick(). value: ${event.currentTarget.value}`);
    props.publishConfigValue("gasConfiguration", event.currentTarget.value);
  }

  const handleReducingClick = (event) => {
    console.log(`OxygenConfig#handleReducingClick(). value: ${event.currentTarget.value}`);
    props.publishConfigValue("reducingPolarity", event.currentTarget.value);
  }

  return (
    <Grid
      item
      container
      spacing={2}
      alignItems="flex-start"
      justify="flex-start"
      direction="column"
    >
      <Grid item container spacing={1} alignItems="center" justify="flex-start">
        <Grid item xs={12} md={3}>
          <Typography>Gas Configuration</Typography>
        </Grid>
        {['Standard', 'Inverse'].map(config => (
          <Grid item key={config}>
            <FormControlLabel
              label={config}
              disabled={props.loggingState === 'logging'}
              control={
                <Radio
                  value={config}
                  checked={props.gasConfiguration === config}
                  classes={{root: classes.radio, checked: classes.checked}}
                  onChange={handleGasClick}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
      <Grid item container spacing={1} alignItems="center" justify="flex-start">
        <Grid item xs={12} md={3}>
          <Typography>Reducing atmosphere sign</Typography>
        </Grid>
        {['Positive', 'Negative'].map(convention => (
          <Grid item key={convention}>
            <FormControlLabel
              label={convention}
              disabled={props.loggingState === 'logging'}
              control={
                <Radio
                  value={convention}
                  checked={props.reducingPolarity === convention}
                  classes={{root: classes.radio, checked: classes.checked}}
                  onChange={handleReducingClick}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

OxygenConfig.propTypes = {
  gasConfiguration: PropTypes.string,
  reducingPolarity: PropTypes.string,
  publishConfigValue: PropTypes.func,
}

export default withStyles(AosClasses)(OxygenConfig);
