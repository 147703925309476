import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { connect } from "redux-zero/react";
import actions from "../../store/actions";
import { Redirect } from "react-router-dom";
import ConfigList from "./ConfigList";
import { Route, Switch } from "react-router-dom";
import Storage from "../../components/Storage";
import Sensors from "../../components/Sensors";
import DateTime from '../../components/DateTime';
import Network from '../../components/Network';
import Update from '../../components/Update';
import UserList from '../../components/UserList';
import { loginStates } from '../../components/Defines.js';

const styles = theme => ({
  root: {}
});

const mapToProps = ({
  connectedProbeReader,
  password,
  passwordExpiry,
  adminClient,
  adminClientId,
  deviceTime,
  users,
  loginState,
}) => ({
  connectedProbeReader,
  password,
  passwordExpiry,
  adminClient,
  adminClientId,
  deviceTime,
  users,
  loginState,
});

class Configuration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false
    };
  }

  publishConfigValue = (field, value) => {
    var topic = `${this.props.adminClientId}/${this.props.connectedProbeReader.id}/config/${field}`;
    console.log(`Configuration#publishConfigValue(). topic: ${topic}. payload: ${value}`);
    var options = { qos: 2 };
    this.props.adminClient.publish(topic, value, options);
  };

  render() {
    const { classes } = this.props;
    return this.props.connectedProbeReader ? (
      <Switch>
        <Route exact path="/configuration">
          <ConfigList
            classes={classes}
            connectedProbeReader={this.props.connectedProbeReader}
          />
        </Route>
        <Route exact path="/configuration/storage">
          {this.props.loginState === loginStates.LOGGED_IN  ?
            <Storage readerConfig={this.props.connectedProbeReader} publishConfigValue={this.publishConfigValue}/> :
            <Redirect to='/connection' />
          }
        </Route>
        <Route exact path="/configuration/sensors">
          {this.props.loginState === loginStates.LOGGED_IN ?
            <Sensors readerConfig={this.props.connectedProbeReader} publishConfigValue={this.publishConfigValue} /> :
            <Redirect to='/connection' />
          }
        </Route>
        <Route exact path='/configuration/date-time'>
          {this.props.loginState === loginStates.LOGGED_IN ?
            <DateTime
              clockTime={this.props.deviceTime}
              readerConfig={this.props.connectedProbeReader}
              publishConfigValue={this.publishConfigValue}
            /> :
            <Redirect to='/connection' />
          }
        </Route>
        <Route exact path='/configuration/network'>
          {this.props.loginState === loginStates.LOGGED_IN ?
            <Network
              address={this.props.connectedProbeReader.address}
              netmask={this.props.connectedProbeReader.netmask}
              dns={this.props.connectedProbeReader.dns}
              interfaceStatus={this.props.connectedProbeReader.interfaceStatus}
              networkStatus={this.props.connectedProbeReader.networkStatus}
              dnsStatus={this.props.connectedProbeReader.dnsStatus}
              internetStatus={this.props.connectedProbeReader.internetStatus}
            /> :
            <Redirect to='/connection' />
          }
        </Route>
        <Route exact path='/configuration/update'>
          {this.props.loginState === loginStates.LOGGED_IN ?
            <Update
              firmwareVersion={this.props.connectedProbeReader.firmwareVersion}
              availableVersion={this.props.connectedProbeReader.availableVersion}
              deviceId={this.props.connectedProbeReader.deviceId}
            /> :
            <Redirect to='/connection'/>
          }
        </Route>
        <Route exact path='/configuration/users'>
          {this.props.loginState === loginStates.LOGGED_IN ?
            <UserList
              users={this.props.users}
              publishConfigValue={this.publishConfigValue}
            /> :
            <Redirect to='/connection' />
          }
        </Route>
      </Switch>
    ) : (
      <Redirect to="/" />
    );
  }
}

Configuration.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(mapToProps, actions)(Configuration));
