import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import RoomIcon from "@material-ui/icons/Room";
import ReplayIcon from "@material-ui/icons/Replay";
import StopIcon from "@material-ui/icons/Stop";
import Hidden from "@material-ui/core/Hidden";

export default function LoggingControls(props){

  const notLoggedIn = (
    <Typography>
      Sign in to control the device logging state.
    </Typography>
  )

  const startStopRefresh = (
    <Grid container spacing={2}>
      <Grid container item xs={9} spacing={2} justify="flex-start">
        <Grid item>
          <Button
            variant="contained"
            className={props.classes.startStopButton}
            onClick={props.handleLoggingToggle}
          >
            {props.loggingState === "idle" ? <PlayArrowIcon /> : <StopIcon />}
              <Hidden xsDown>
                {props.loggingState === "idle" ? ("Start logging") : ("Stop logging")}
              </Hidden>
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={props.classes.markerButton}
            onClick={props.handleMarker}
          >
            <RoomIcon />
              <Hidden xsDown>
                Insert marker
              </Hidden>
            </Button>
        </Grid>
      </Grid>
      <Grid container item xs={3} justify="flex-end">
        <Grid item>
          <Button
            variant="contained"
            className={props.classes.refreshButton}
            onClick={props.handleReset}
          >
            <ReplayIcon />
              <Hidden xsDown>
                Refresh
              </Hidden>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )

  return props.loggedIn ? startStopRefresh : notLoggedIn;
}
