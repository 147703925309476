import {tempLoginStates, loginStates} from "../components/Defines.js";

const actions = store => ({
    connectMQTT: state => ({ mqttConnected: true }),
    connectToDeviceIP: (state, address) => ({connectDeviceIP: address}),
    disconnectMQTT: state => {console.log("disconnectMQTT()"); return { mqttConnected: false }},
    mqttClientConnect: (state, client) => ({mqttClient: client}),
    mqttClientDisconnect: (state) => ({mqttClient: null}),
    connectProbeReader: (state, reader) => ({connectedProbeReader: reader}),
    disconnectProbeReader: (state) => ({connectedProbeReader: null}),
    updateOxygenGraphData: (state, newO2Time, newOxygen) => {
      var returnVar;
      (state.connectedProbeReader !== null && state.connectedProbeReader.loggingState === "logging") ?
      returnVar =  {
        O2Timestamps: newO2Time,
        oxygenData: newOxygen,
        oxygenReadout: newOxygen[newOxygen.length - 1]
      } :
      returnVar = {
        oxygenReadout: newOxygen[newOxygen.length - 1]
      };
      return returnVar;
    },
    updateTemperatureGraphData: (state, newTempTime, newTemp) => {
      var returnVar;
      state.connectedProbeReader !== null && state.connectedProbeReader.loggingState === "logging" ?
      returnVar = {
        tempTimestamps: newTempTime,
        temperatureData: newTemp,
        temperatureReadout: newTemp[newTemp.length - 1]
      } :
      returnVar = {
        temperatureReadout: newTemp[newTemp.length - 1]
      };
      return returnVar;
    },
    clearGraphData: (state) => ({O2Timestamps: [], oxygenData: [], tempTimestamps: [], temperatureData: [], timeMarkers: []}),
    setProbeReaderName: (state, name) => ({probeReaderName: name}),
    setPassword: (state, newPassword) => ({password: newPassword}),
    setPasswordExpiry: (state, newExpiry) => ({passwordExpiry: newExpiry}),
    setPasswordAndExpiry: (state, newPassword, newExpiry) => {
      return {passwordExpiry: newExpiry, password: newPassword};
    },
    tempRequestAuth: (state, password, expiry) => {
      return ({
        password: password,
        passwordExpiry: expiry,
        tempLoginState: tempLoginStates.TEMP_PENDING,
        authConnectionMessage: ""
      });
    },
    clearTempLogin: (state) => ({
      password: "",
      expiry: "",
      tempLoginState: tempLoginStates.TEMP_NO_AUTH,
      authConnectionMessage: ""
    }),
    setTempLogin: (state, password, expiry) => ({
      password: password,
      expiry: expiry,
      tempLoginState: tempLoginStates.TEMP_AUTHED
    }),
    tempLoginApproved: (state, expiry) => ({
      passwordExpiry: expiry,
      tempLoginState: tempLoginStates.TEMP_AUTHED,
      authConnectionMessage: "Temporary login approved."
    }),
    tempLoginDenied: (state) => ({
      password: undefined,
      passwordExpiry: undefined,
      tempLoginState: tempLoginStates.TEMP_NO_AUTH,
      authConnectionMessage: "Temporary login denied."
    }),
    logout: (state) => ({
      loginState: loginStates.LOGOUT_PENDING
    }),
    logoutSuccess: (state) => {
      console.log(`actions#logoutSuccess`);
      return ({
        adminClient: null,
        loginState: loginStates.NOT_LOGGED_IN,
        authConnectionMessage: ""
      })
    },
    login: (state, username, password) => ({
      manualCredentials: {
        username: username,
        password: password
      },
      loginState: loginStates.LOGIN_PENDING,
      authConnectionMessage: ""
      }
    ),
    loginSuccess: (state) => ({
      loginState: loginStates.LOGGED_IN,
      authConnectionMessage: ""
    }),
    setAdminClient: (state, client) => ({
      adminClient: client
    }),
    setAuthConnectioMessage: (state, message) => ({
      authConnectionMessage: message
    }),
    setAdminClientId: (state, id) => ({
      adminClientId: id
    }),
    addTimeMarkers: (state, marker) => ({
      timeMarkers: [...state.timeMarkers, ...marker],
    }),
    setDeviceTime:(state, newMoment) =>({
      deviceTime: newMoment,
    }),
    setUsers:(state, newUsers) => ({
      users: newUsers,
    }),
  });

  export default actions;
