const aosColours = {
  red: "#ec1739",
  blue: "#4190d4",
  orange: "#f69219",
  aqua: "#00b49d",
  black: "#000000"
};

export const AosStyle = {
  palette: {
    primary: {
      main: aosColours.blue
    },
    secondary: {
      main: aosColours.red
    }
  }
};

export const AosClasses = theme => ({
  root: {},
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  paper: {
    padding: theme.spacing(3, 2)
  },
  button: {
    margin: theme.spacing(1)
  },
  connectButton: {
    backgroundColor: aosColours.blue,
    color: "#fff"
  },
  startStopButton: {
    backgroundColor: aosColours.black,
    color: "#fff"
  },
  markerButton: {
    backgroundColor: aosColours.black,
    color: "#fff"
  },
  refreshButton: {
    backgroundColor: aosColours.orange,
    color: "#fff"
  },
  prevDevices: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold"
  },
  newDevice: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 175
  },
  card: {
    maxWidth: 250
  },
  details: {
    //display: 'flex',
    //flexDirection: 'column',
  },
  content: {
    flex: "1 0 auto"
  },
  cover: {
    height: 150
  },
  fab: {
    margin: theme.spacing(1),
    float: "right"
  },
  oxygenCard: {
    minWidth: "350",
    textAlign: "center",
    backgroundColor: aosColours.blue
  },
  temperatureCard: {
    minWidth: "350",
    textAlign: "center",
    backgroundColor: aosColours.red
  },
  title: {
    fontSize: 28,
    color: "white"
  },
  thermocoupleButtonSelected: {
    backgroundColor: aosColours.aqua,
    color: "white",
    fontSize: "1rem",
    fontWeight: "bold"
  },
  thermocoupleButtonUnselected: {
    backgroundColor: "white",
    color: "black",
    fontSize: "1rem",
    fontWeight: "bold"
  },
  radio: {
    "&$checked": {
      color: aosColours.aqua
    }
  },
  checked: {}
});
