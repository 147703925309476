import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { AosClasses }  from '../../AosStyle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

function Update (props) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography component='h1' variant='h5'>
          Update
        </Typography>
      </Grid>
      <Grid item container spacing={1} >
        <Grid item>
          <Typography>Installed:</Typography>
        </Grid>
        <Grid item>
          <Typography><strong>{props.firmwareVersion}</strong></Typography>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <Typography>Available:</Typography>
          </Grid>
          <Grid item>
            <Typography><strong>{props.availableVersion}</strong></Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} />
        <Grid item container spacing={1}>
          <Grid item>
            <Typography>Device ID:</Typography>
          </Grid>
          <Grid item>
            <Typography><strong>{props.deviceId}</strong></Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(AosClasses)(Update);
