import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { AosClasses } from "../../AosStyle";
import Button from "@material-ui/core/Button";
import OxygenConfig from "./OxygenConfig";

export function Sensors(props) {

  function handleClick(event) {
    props.publishConfigValue("thermocoupleType", event.currentTarget.value);
  }

  const { classes } = props;

  return (
    <Grid container spacing={2} direction="column"
    >
      <Grid item>
        <Typography component="h1" variant="h4">
          Sensors
        </Typography>
      </Grid>
      <Grid item>
        <Typography component="h2" variant="h5">
          Thermocouple type
        </Typography>
      </Grid>
      <Grid item container spacing={2} xs={12}>
        {['B', 'E', 'J', 'K', 'N', 'R', 'S', 'T'].map((tcType) => (
          <Grid item key={tcType}>
            <Button
              value={tcType}
              variant="outlined"
              onClick={handleClick}
              disabled={props.readerConfig.loggingState === 'logging'}
              className={
                props.readerConfig.thermocoupleType === tcType
                  ? classes.thermocoupleButtonSelected
                  : classes.thermocoupleButtonUnselected
              }
            >
              {tcType}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Typography component="h2" variant="h5">
          Oxygen sensor
        </Typography>
      </Grid>
      <OxygenConfig
        gasConfiguration={props.readerConfig.gasConfiguration}
        reducingPolarity={props.readerConfig.reducingPolarity}
        publishConfigValue={props.publishConfigValue}
        loggingState={props.readerConfig.loggingState}
      />
    </Grid>
  );
}

export default withStyles(AosClasses)(Sensors);
