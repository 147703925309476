import React from "react";
import { connect } from "redux-zero/react";
import actions from "../../store/actions.js";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ConfigLogin from "../ConfigLogin";

const mapToProps = state => ({});

class ConnectionStatus extends React.Component {
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
    }
  }

  render = () => {
    return (
      <Grid
        container
        className={this.props.classes.root}
        spacing={4}
        alignItems="flex-start"
        justify="space-around"
      >
        <Grid item xs={12} sm={4} md={4}>
          <Typography>You are connected to</Typography>
          <Typography component="h5" variant="h5">
            {this.props.name}
          </Typography>
          <Typography component="h6" variant="h6">
            {this.props.ip}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={this.props.endConnection}
          >
            Disconnect
          </Button>
        </Grid>
        <Grid item xs={12} sm={8} md={8}>
          <ConfigLogin
            tempLoginState={this.props.tempLoginState}
            classes={this.props.classes}
            requestAdminPermission={this.props.requestAdminPermission}
            forgetBrowserLogin={this.props.forgetBrowserLogin}
            login={this.props.login}
            tempLogin={this.props.tempLogin}
            loginState={this.props.loginState}
            connectionMessage={this.props.connectionMessage}
            logout={this.props.logout}
          />
        </Grid>
      </Grid>
    );
  };
}

export default connect(mapToProps, actions)(ConnectionStatus);
