import React from "react";
import Typography from "@material-ui/core/Typography";
import {Button} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";


export default function DeviceCard(props) {

  return(
    <Card className={props.styleClasses.card}>
      <div className={props.styleClasses.details}>
        <CardMedia className={props.styleClasses.cover} image="probe-reader.jpg" title="AOS 02TA-2200"/>
        <CardContent className={props.styleClasses.content}>
          <Typography component="h5" variant="h5">
            {props.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            IP: {props.ip}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={() => props.startConnection(props.name, props.ip, props.port)}>
          Connect
        </Button>
        <IconButton className={props.styleClasses.button} onClick={() => props.removeConnection(props.name, props.ip, props.port)} aria-label="delete">
          <DeleteIcon/>
        </IconButton>
      </CardActions>
    </div>
    </Card>
  )
}
