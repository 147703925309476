import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { connect } from "redux-zero/react";
import Container from "@material-ui/core/Container";
import actions from "../../store/actions";
import { get, set } from "idb-keyval";
import DeviceCollection from '../../components/DeviceCollection'
import ConnectionStatus from '../../components/ConnectionStatus'
import moment from "moment";
import {loginStates} from "../../components/Defines.js";
import {AosClasses} from "../../AosStyle.js";
import CircularProgress from "@material-ui/core/CircularProgress";


const mapToProps = (state) => ({
  mqttConnected: state.mqttConnected,
  mqttClient: state.mqttClient,
  probeReaderName: state.probeReaderName,
  tempLoginState: state.tempLoginState,
  loginState: state.loginState,
  authConnectionMessage: state.authConnectionMessage,
  password: state.password
});

class Connection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      connecting: false,
      probeReaders: null,
      connectingText: "",
    };
    get("password").then(indexPassword => {
      get("expiry").then(indexExpiry => {
        if(typeof(indexPassword) !== "undefined" && indexPassword !== ""){
          if(moment(indexExpiry).isAfter(moment.now())){
            this.props.setTempLogin(indexPassword, indexExpiry);
          }
        }
      });
    });
  }

  handleModalOpen = () => {
    this.setState({modalOpen: true});
  }

  handleModalClose = value => {
    this.setState({modalOpen: false});
  };

  componentDidMount() {
    get("readers").then(val => {
      this.setState({ probeReaders: val });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.mqttConnected) {
        this.setState({ connecting: false });
      }
    }
  }

  saveConnectionToDatabase = (name, ip, port) => {
    var alreadyInList = false;
    get("readers").then(val => {
      if (val === undefined) {
        val = [];
      }
      val.forEach(element => {
        if (
          element.name === name &&
          element.url === ip &&
          element.port === port
        ) {
          alreadyInList = true;
        }
      });
      if (!alreadyInList) {
        val.push({ name: name, url: ip, port: port });
      }
      set("readers", val);
      this.setState({probeReaders : val});
    });
  }

  removeConnectionFromDatabase = (name, ip, port) => {
    console.log("Home.removeConnectionFromDatabase()");
    get("readers").then(val => {
      var newArray = [];
      if (val === undefined) {
        newArray = [];
      }

      val.forEach(element => {
        if (
          element.name !== name ||
          element.url !== ip ||
          element.port !== port
        ) {
          newArray.push(element);
        }
      });
      set("readers", newArray);
      this.setState({ probeReaders: newArray });
    });
  }

  startConnection = (name, url, port) => {
    console.log("Home.startConnection");
    this.setState({ connecting: true});
    this.props.setProbeReaderName(name);
    this.props.connectToDeviceIP(url);
  };

  endConnection = () => {
    console.log("Home.endConnection()");
    this.props.setProbeReaderName('');
    this.props.connectToDeviceIP('');
    this.props.clearGraphData();
  }

  requestAdminPermission = (expiry) => {
    const generateNewPassword = () =>
    {
      console.log("get or create password");
      get("password").then( indexPassword =>
      {
        if(typeof(indexPassword) !== 'undefined' && indexPassword !== "")
        {
          console.log(`password in index, ${indexPassword}`);
        } else
        {
          console.log("generating new password");
          console.log(this.props.mqttClient);
          var array = new Uint32Array(3);
          window.crypto.getRandomValues(array);
          indexPassword = array.reduce((existing, extra) => {return existing.concat(extra.toString(16))}, "");
        }
        this.props.tempRequestAuth(indexPassword, expiry);
      });
    }

    if(typeof(this.props.password) === "undefined" || this.props.password === "")
    {
      console.log("no password found in state");
      generateNewPassword();
    } else if(moment(this.props.expiry).isBefore(moment.now()))
    {
      console.log("found expired password in state");
      generateNewPassword();
    } else
    {
        console.log("reusing existing unexpired password.");
        this.props.tempLoginApproved(this.props.password, this.props.expiry);
    }
  }

  login = (username, password) => {
    if(this.props.loginState === loginStates.NOT_LOGGED_IN){
      this.props.login(username, password);
    }
  }

  logout = () => {
    this.props.logout();
  }

  tempLogin = () => {
    if(this.props.loginState === loginStates.NOT_LOGGED_IN){
      this.props.login(this.props.mqttClient.options.clientId, this.props.password);
    }
  }

  forgetBrowserLogin = () => {
    set("password", "").then(() => {
      this.props.clearTempLogin();
    });
  }

  render = () => {
    const { classes } = this.props;
    return (
      <Container maxWidth="xl" className={classes.root}>
        {this.props.mqttConnected ?
          (
            <ConnectionStatus
              name={this.props.probeReaderName}
              ip={this.props.mqttClient.options.hostname}
              tempLoginState={this.props.tempLoginState}
              requestAdminPermission={this.requestAdminPermission}
              forgetBrowserLogin={this.forgetBrowserLogin}
              login={this.login}
              tempLogin={this.tempLogin}
              loginState={this.props.loginState}
              connectionMessage={this.props.authConnectionMessage}
              classes={classes}
              endConnection={this.endConnection}
              logout={this.logout}
            />
          ) :
          (
            this.state.connecting ? (
              <CircularProgress variant="indeterminate" color="primary"/>
              ) :
              (<DeviceCollection
                probeReaders={this.state.probeReaders}
                saveConnectionToDatabase={this.saveConnectionToDatabase}
                removeConnection={(name, ip, port) => {this.removeConnectionFromDatabase(name, ip, port)}}
                startConnection={this.startConnection}
                classes={classes}
              />
            )
          )
        }
        </Container>
      );
  }
}

Connection.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(AosClasses)(
  connect(
    mapToProps,
    actions
  )(Connection)
);
