import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Mqtt from "./components/Mqtt";
import Connection from "./routes/Connection";
import Chart from "./routes/Chart";
import Settings from "./routes/settings";
import Configuration from "./routes/Configuration";
import NotFound from "./routes/404";
import { AosStyle } from "./AosStyle.js";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import "typeface-roboto";

const theme = createMuiTheme(AosStyle);

class App extends React.Component {
  render() {
    return (
      <Router>
        <ThemeProvider theme={theme}>
          <div>
            <Header>
              <Mqtt>
                <Switch>
                  <Route path="/" exact component={Connection} />
                  <Route path="/connection" component={Connection} />
                  <Route path="/chart/" component={Chart} />
                  <Route path="/configuration/" component={Configuration} />
                  <Route path="/settings/" component={Settings} />
                  <Route component={NotFound} />
                </Switch>
              </Mqtt>
            </Header>
          </div>
        </ThemeProvider>
      </Router>
    );
  }
}

export default App;
