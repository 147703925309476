import React from "react";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {withStyles} from "@material-ui/styles";
import {connect} from "redux-zero/react";
import actions from "../../store/actions";
import Plotly from "plotly.js-basic-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import Container from "@material-ui/core/Container";
import {Redirect} from "react-router-dom";
import moment from "moment";
import LoggingControls from "../../components/LoggingControls";
import {loginStates} from "../../components/Defines.js";
import {AosClasses} from "../../AosStyle.js";

const Plot = createPlotlyComponent(Plotly);

const selectorOptions = {
  buttons: [
    {
      step: 'all',
      label: 'auto'
    }, {
      step: 'minute',
      stepmode: 'backward',
      count: 1,
      label: '1m'
    }, {
      step: 'minute',
      stepmode: 'backward',
      count: 2,
      label: '2m'
    }, {
      step: 'minute',
      stepmode: 'backward',
      count: 5,
      label: '5m'
    }, {
      step: 'minute',
      stepmode: 'backward',
      count: 10,
      label: '10m'
    }, {
      step: 'minute',
      stepmode: 'backward',
      count: 30,
      label: '30m'
    }, {
      step: 'hour',
      stepmode: 'backward',
      count: 1,
      label: '1h'
    }, {
      step: 'hour',
      stepmode: 'backward',
      count: 2,
      label: '2h'
    }, {
      step: 'hour',
      stepmode: 'backward',
      count: 5,
      label: '5h'
    }, {
      step: 'hour',
      stepmode: 'backward',
      count: 10,
      label: '10h'
    }, {
      step: 'day',
      stepmode: 'backward',
      count: 1,
      label: '24h'
    }, {
      step: 'day',
      stepmode: 'backward',
      count: 2,
      label: '48h'
    }
  ],
  yanchor: 'bottom',
  visible: true
}

const legendOptions = {
  orientation: "h",
  xanchor: "center",
  yanchor: "top",
  yref: 'paper',
  x: 0.5,
  y: -0.20
}

const mobileConfig =
{
  xaxis: {
    tickmode: "auto",
    rangeselector: selectorOptions,
    fixedrange: false,
    title: undefined,
    nticks: 5
  },
  yaxis: {
    fixedrange: true,
    title: undefined
  },
  yaxis2: {
    overlaying: "y",
    side: "right",
    showgrid: false,
    fixedrange: true,
    title: undefined
  },
  legend: legendOptions,
  dragmode: "pan",
  hovermode: false,
  showlegend: false,
  margin: {
    l: 25,
    r: 50,
    b: 75,
    t: 10,
    pad: 4
  },
  shapes: [],
}

const desktopConfig =
{
  xaxis: {
    tickmode: "auto",
    rangeselector: selectorOptions,
    fixedrange: false,
    title: "Time",
    nticks: 10
  },
  yaxis: {
    fixedrange: true,
    title: "Temperature (C)"
  },
  yaxis2: {
    overlaying: "y",
    side: "right",
    showgrid: false,
    fixedrange: true,
    title: "Oxygen (V)"
  },
  legend: legendOptions,
  dragmode: 'pan',
  hovermode: 'closest',
  showlegend: true,
  margin: {
    l: 75,
    r: 75,
    b: 75,
    t: 50,
    pad: 4
  },
  shapes: [],
}

const mapToProps = state => ({
  mqttClient : state.mqttClient,
  O2Timestamps : state.O2Timestamps,
  oxygenData : state.oxygenData,
  tempTimestamps : state.tempTimestamps,
  temperatureData : state.temperatureData,
  loginState : state.loginState,
  adminClient: state.adminClient,
  connectedProbeReader: state.connectedProbeReader,
  adminClientId: state.adminClientId,
  temperatureReadout: state.temperatureReadout,
  oxygenReadout: state.oxygenReadout,
  timeMarkers: state.timeMarkers,
});

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layout : desktopConfig,
      temperatureNotation: 0,
      oxygenNotation: 0,
      markers: this.formatMarkers(this.props.timeMarkers)
    };

    this.frames = [];
    this.style = {
      width: "100%",
      height: "65vh"
    };

    this.underRelayouting = false;
    this.countIndex = 0;
    this.follow = true;
    this.autoscale = true;
    this.interval = 0;
    this.intervals = [
      60, 120, 300, 600, 1800,
      3600, 7200, 18000, 36000, 86400, 172800
    ];
  };


  printOxygen = () => {
    var oxygenString = "";

    switch (this.state.oxygenNotation) {
      case 0:
        var oxygenVal = parseFloat(this.props.oxygenReadout) * 1000;
        oxygenVal = oxygenVal.toFixed(2);
        oxygenString = `${oxygenVal} mV`
        break;
      case 1:
        const nernstConstant = -46.421;
        var tcTemp = this.props.temperatureReadout;
        console.debug(tcTemp);
        var o2Voltage = parseFloat(this.props.oxygenReadout) * 1000;
        o2Voltage = o2Voltage.toFixed(2);
        console.debug(o2Voltage);
        var pO2 = 0.2095 * Math.exp(nernstConstant * (o2Voltage / (tcTemp + 273.15)));
        console.debug("Calc value:" + pO2);
        if (pO2 >= 2.0) {
          oxygenString = "Error";
        } else if (pO2 > 1.1) {
          oxygenString = `> 100 %`;
        } else if (pO2 > 0.001) {
          oxygenString = `${ (pO2 * 100).toFixed(1)} %`;
        } else if (pO2 > 0.000001) {
          oxygenString = `${ (pO2 * 1000000).toFixed(0)} ppm`;
        } else {
          oxygenString = `${pO2.toExponential(2)}`;
        }
        break;
      default:
          oxygenString = "";
          console.debug("printOxygen(): error printing oxygen value");
      }
      return oxygenString;
    };

    printTemperature = () => {
      var tempString = "";
      switch(this.state.temperatureNotation){
        case 0:
          tempString = this.props.temperatureReadout.toFixed(1);
          tempString += ` ${String.fromCharCode(176)}C`;
          break;
        case 1:
          var celsius = this.props.temperatureReadout;
          tempString = parseFloat((celsius * 9) / 5 + 32).toFixed(1);
          tempString += ` ${String.fromCharCode(176)}F`;
          break;
        case 2:
          tempString = parseFloat(this.props.temperatureReadout + 273.15).toFixed(1);
          tempString += " K";
          break;
        default:
          tempString = "";
          console.debug("printTemperature(): error printing temperature value");
      }
      return tempString;
    }

  changeOxygenUnits = () => {
    var index = this.state.oxygenNotation;
    if (index === 1) {
      index = 0;
    } else {
      index = index + 1;
    }
    this.setState({oxygenNotation: index});
  };

  changeTemperatureUnits = () => {
    var index = this.state.temperatureNotation;
    if (index ===  2) {
      index = 0;
    } else {
      index = index + 1;
    }
    this.setState({temperatureNotation: index});
  };

  changeToMobileChart() {
    if (window.innerWidth < 500)
    {
      this.setState(Object.assign(mobileConfig, {shapes: this.state.markers}));
    } else
    {
      this.setState(Object.assign(desktopConfig, {shapes: this.state.markers}));
    }
  }

  componentDidMount() {
    this.changeToMobileChart();
    window.addEventListener("resize", this.changeToMobileChart.bind(this));
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if(this.follow)
      {
        let extents = this.getExtents(this.props.tempTimestamps, this.props.O2Timestamps);
        if(this.autoscale){
          let timeRange = (extents[1] - extents[0]) / 1000.0;
          if(timeRange >= this.interval){
            let newInterval = this.intervals.find(el => el > timeRange);
            console.debug(newInterval);
            if(newInterval === undefined){
              this.handleRelayout({'xaxis.range' : 'autoscale'});
            } else {
              this.interval = newInterval;
            }
          }
        }
        this.scaleXaxis(extents, this.interval);
      }

      if(prevProps.connectedProbeReader.loggingState === "idle" &&
        this.props.connectedProbeReader.loggingState === "logging"){
        this.props.clearGraphData();
      }

      if(prevProps.timeMarkers !== this.props.timeMarkers){
        this.setState(Object.assign(this.state.layout, {shapes: this.formatMarkers(this.props.timeMarkers)}));
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.changeToMobileChart.bind(this));
  }

  render() {
    const {classes} = this.props;

    return this.props.mqttClient
      ? (<React.Fragment>
        <Container maxWidth="xl" className={classes.root}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={4}
            >
            <Grid item xs={12} md={6}>
              <Card className={classes.temperatureCard}>
                <CardActionArea onClick={this.changeTemperatureUnits}>
                  <CardContent>
                    <Typography className={classes.title}>
                      {this.printTemperature()}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className={classes.oxygenCard}>
                <CardActionArea onClick={this.changeOxygenUnits}>
                  <CardContent>
                    <Typography className={classes.title}>
                      {this.printOxygen()}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
          <br/>
          <Plot
            divId='aosPlot'
            useResizeHandler
            data={[{
                type: "scattergl",
                mode: "lines",
                x: this.props.O2Timestamps,
                y: this.props.oxygenData,
                hoverinfo: "x",
                hovertemplate: '%{y:.3f}<extra></extra>',
                name: "Oxygen",
                yaxis: "y2",
                marker: {
                  color: "blue"
                }
              }, {
                type: "scattergl",
                mode: "lines",
                x: this.props.tempTimestamps,
                y: this.props.temperatureData,
                hoverinfo: "x",
                hovertemplate: '%{y:.2f}<extra></extra>',
                name: "Temperature",
                marker: {
                  color: "red"
                }
              },
            ]}
            layout={this.state.layout}
            style={this.style}
            config={{displayModeBar: false, responsive: true, scrollZoom: true}}
            // onRelayouting={(eventParameters) => this.handleRelayouting(eventParameters)}
            onRelayout={this.handleRelayout}
            // onRelayout={(eventParameters) => console.debug("onRelayout event")}
            onInitialized={(figure, gd) => this.handleInitialization(gd)}
          />
        <LoggingControls
          classes={classes}
          loggedIn={this.props.loginState === loginStates.LOGGED_IN}
          loggingState={this.props.connectedProbeReader.loggingState}
          handleLoggingToggle={this.handleLoggingToggle}
          handleReset={this.handleReset}
          handleMarker={this.handleMarker}
        />
        </Container>
      </React.Fragment>)
      : (<Redirect to="/"/>);
  }

  handleInitialization(gd){
    gd.removeAllListeners('plotly_relayouting');
    gd.on('plotly_relayouting', this.handleRelayouting);
    let selector = document.getElementsByClassName('rangeselector')[0]
    let buttons = selector.getElementsByClassName('button');
    for(let b of buttons){
      b.onclick = this.handleRangeClick;
    }
  }

  handleRangeClick = (click) => {
    console.debug('handleRangeClick');
    let count = click.originalTarget.__data__._input.count;
    let step = click.originalTarget.__data__._input.step;
    this.follow = true;
    this.autoscale = false;
    switch(step)
    {
      case 'minute':
        this.interval = count * 60;
        break;
      case 'hour':
        this.interval = count * 60 * 60;
        break;
      case 'day':
        this.interval = count * 60 * 60 * 24;
        break;
      case 'all':
        this.interval = 0;
        this.autoscale = true;
        break;
      default:
        this.interval = undefined;
        this.autoscale = true;
        break;
    }
    if(this.autoscale === false)
    {
      this.scaleXaxis(this.getExtents(this.props.tempTimestamps, this.props.O2Timestamps), this.interval);
    } else
    {
      this.handleRelayout({'xaxis.range' : 'autoscale'});
    }
  }

  scaleXaxis(extents, interval){
    let newLayout = this.state.layout;
    let range0;
    let range1;
    let timeRange = (extents[1] - extents[0]) / 1000.0;
    if(timeRange > interval) {
      //scale to follow the leading edge
      range1 = new Date(extents[1]);
      range0 = new Date(extents[1].setSeconds(extents[1].getSeconds() - interval));
    }else if(timeRange < interval) {
      //scale to put time-zero at the left-hand side
      range0 = new Date(extents[0]);
      range1 = new Date(extents[0].setSeconds(extents[0].getSeconds() + interval));
    }
    range0 = moment(range0).format("YYYY-MM-DD HH:mm:ss.SSSS");
    range1 = moment(range1).format("YYYY-MM-DD HH:mm:ss.SSSS");
    let range = [range0, range1];
    newLayout.xaxis.range = range;
    newLayout.xaxis.autorange = false;
    this.setState(newLayout);
    this.handleRelayout({'xaxis.range' : range});
  }


  handleRelayouting = (eventParameters) => {
    console.debug('handleRelayouting');
    this.follow = false;
    var gd = document.getElementById('aosPlot');
    gd.removeListener('plotly_relayouting', this.handleRelayouting);
    // document.getElementById('aosPlot').removeEventListener('plotly_relayout', this.handleRelayout);
    let newLayout = this.state.layout;
    // let newLayout = {};

    if (!this.underRelayouting) {
      this.underRelayouting = true;
      // console.debug(`countIndex: ${this.countIndex++}. underRelayouting: ${this.underRelayouting}`);
      let range = this.extractRange(eventParameters);
      if (typeof range[0] !== 'undefined' && typeof range[1] !== 'undefined' && range[0] !== range[1]) {
          newLayout.yaxis2.autorange = false;
          let o2Range = this.yrange(this.props.O2Timestamps, this.props.oxygenData, range);
          newLayout.yaxis2.range = this.constrainRange(o2Range, 0.001);

          newLayout.yaxis.autorange = false;
          let tempRange = this.yrange(this.props.tempTimestamps, this.props.temperatureData, range);
          newLayout.yaxis.range = this.constrainRange(tempRange, 1.0);
        }
      else {
        newLayout.yaxis.autorange = true;
        newLayout.yaxis2.autorange = true;
      }
        this.setState({layout : newLayout});
    } else {
      // console.debug('handleRelayouting, underRelayouting: ' + this.underRelayouting);
      this.underRelayouting = false;
    }
    gd.on('plotly_relayouting', this.handleRelayouting);
    // document.getElementById('aosPlot').addEventListener('plotly_relayout', this.handleRelayout);
  }

  handleRelayout = (eventParameters) => {
    // console.debug("handleRelayout()");
    let newLayout = this.state.layout;
    // console.debug('handleRelayout');
    let range = this.extractRange(eventParameters);
    if (typeof range[0] !== 'undefined' && typeof range[1] !== 'undefined' && range[0] !== range[1]) {
      newLayout.yaxis2.autorange = false;
      let o2Range = this.yrange(this.props.O2Timestamps, this.props.oxygenData, range);
      newLayout.yaxis2.range = this.constrainRange(o2Range, 0.001);

      newLayout.yaxis.autorange = false;
      let tempRange = this.yrange(this.props.tempTimestamps, this.props.temperatureData, range);
      newLayout.yaxis.range = this.constrainRange(tempRange, 1.0);
    } else {
      newLayout.yaxis.autorange = true;
      newLayout.yaxis2.autorange = true;
    }
    this.setState({layout : newLayout});
  }


  yrange(xdata, ydata, xrange) {
    const start = xrange[0];
    const end = xrange[1];
    const firstIndex = xdata.findIndex(element => element > start);
    const secondIndex = xdata.findIndex(element => element > end);
    const subRange = ydata.slice(firstIndex, secondIndex);
    const ymin = Math.min(...subRange);
    const ymax = Math.max(...subRange);
    return [ymin, ymax];
  }

  constrainRange(range, minimum) {
    let mid = (range[1] + range[0]) / 2.0;
    let difference = (range[1] - range[0]);
    if (difference < minimum) {
      difference = minimum / 2.0;
    } else {
      difference = difference * .55;
    }
    return [
      mid - difference,
      mid + difference
    ];
  }

  extractRange(eventParameters) {
    let range = eventParameters["xaxis.range"];
    if (typeof range !== 'undefined') {
      return range;
    } else {
      return [
        eventParameters["xaxis.range[0]"], eventParameters["xaxis.range[1]"]
      ];
    }
  }

  getExtents(timeArray1, timeArray2)
  {
    let firstTime1 = new Date(timeArray1[0]);
    let firstTime2 = new Date(timeArray2[0]);
    let first = firstTime1 <= firstTime2 ? firstTime1 : firstTime2;
    let lastTime1 = new Date(timeArray1[timeArray1.length - 1]);
    let lastTime2 = new Date(timeArray2[timeArray2.length - 1]);
    let last = lastTime1 >= lastTime2 ? lastTime1 : lastTime2;
    return [first, last];
  }

  handleLoggingToggle = () => {
    var topic = `${this.props.adminClientId}/${this.props.connectedProbeReader.id}/config/logging`;
    var message = "toggle";
    var options = {qos: 2};
    this.props.adminClient.publish(topic, message, options);
  }

  handleReset = () => {
    var topic = `${this.props.adminClientId}/${this.props.connectedProbeReader.id}/config/logging`;
    var message = "reset";
    var options = {qos: 2};
    if(this.props.connectedProbeReader.loggingState === "idle"){
      this.props.clearGraphData();
    }
    this.props.adminClient.publish(topic, message, options);
  }

  handleMarker = () => {
    var topic = `${this.props.adminClientId}/${this.props.connectedProbeReader.id}/config/logging`;
    var message = "marker";
    var options = {qos: 2};
    this.props.adminClient.publish(topic, message, options);
  }

  formatMarkers = (markers) => {
    var arr = [];
    markers.forEach(marker =>{
      arr.push({
        type: "line",
        x0: marker.format("YYYY-MM-DD HH:mm:ss.SS"),
        y0: -2,
        x1: marker.format("YYYY-MM-DD HH:mm:ss.SS"),
        y1: 2,
        yref: "y2",
        line: {
          color: "black",
          width: 2,
          dash: "dash",
        }
      });
    });
    return arr;
  }

}

export default withStyles(AosClasses)(connect(mapToProps, actions)(Chart));
