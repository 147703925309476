import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { AosClasses } from "../../AosStyle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Button from "@material-ui/core/Button";
import moment from "moment";

function ManualTimeConfig(props) {
  const [manualDateTime, setManualDateTime] = React.useState(
    props.manualDateTime
  );

  const handleDateChange = newDate => {
    var holder = moment(manualDateTime);
    holder.year(newDate.years());
    holder.month(newDate.months());
    holder.date(newDate.date());
    setManualDateTime(holder);
  };

  const handleTimeChange = newTime => {
    var holder = moment.utc(manualDateTime);
    holder.hour(newTime.hours());
    holder.minute(newTime.minutes());
    holder.second(0);
    holder.millisecond(0);
    setManualDateTime(holder);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid
        item
        container
        direction="row"
        spacing={2}
        alignItems="flex-start"
        justify="flex-start"
      >
        <Grid item container spacing={2} direction="row" alignItems="center">
          <Grid item>
            <Typography>Date</Typography>
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              autoOk
              variant="dialog"
              format="DD/MM/YYYY"
              id="date-picker-inline"
              value={manualDateTime}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              disabled={props.loggingState}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} direction="row" alignItems="center">
          <Grid item>
            <Typography>Time</Typography>
          </Grid>
          <Grid item>
            <KeyboardTimePicker
              value={manualDateTime}
              onChange={handleTimeChange}
              disabled={props.loggingState}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={2}
          direction="row"
          alignItems="center"
          justify="flex-start"
        >
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                props.publishConfigValue(
                  "manualDateTime",
                  JSON.stringify(manualDateTime.valueOf())
                );
              }}
              disabled={props.loggingState}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

export default withStyles(AosClasses)(ManualTimeConfig);
