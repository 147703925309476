const worker = () => {
    self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
        if (!e) return;

        var newO2TimestampArray = e.data.oxygenTime
        var newOxygenArray = e.data.oxygenData
        var newTempTimeArray = e.data.tempTime
        var newTemperatureArray = e.data.tempData

        var firstTime = e.data.epochTimestamps[0];
        var lastTime = e.data.epochTimestamps[e.data.epochTimestamps.length - 1];
        var firstIndex = 0;
        var lastIndex = 0;
        var firstFlag = false;

        if(e.data.signal === 'oxygen'){
          if(e.data.span === 1){
              newO2TimestampArray = newO2TimestampArray.concat(e.data.timestamps);
              newOxygenArray = newOxygenArray.concat(e.data.values);
          }else{
            for(let i = 0; i < e.data.oxygenTime.length; i++){
                var O2Time = new Date(e.data.oxygenTime[i]);
                var O2EpochTime = O2Time.getTime();
                if(O2EpochTime >= firstTime && O2EpochTime <= lastTime){
                    if(!firstFlag){
                        firstIndex = i;
                        firstFlag = true;
                    }
                    lastIndex = i;
                }
            }

            newO2TimestampArray.splice.apply(newO2TimestampArray, [firstIndex, (lastIndex - firstIndex) + 1].concat(e.data.timestamps));
            newOxygenArray.splice.apply(newOxygenArray, [firstIndex, (lastIndex - firstIndex) + 1].concat(e.data.values));
          }
        }else if(e.data.signal === 'temperature'){
          if(e.data.span === 1){
            newTempTimeArray = newTempTimeArray.concat(e.data.timestamps);
            newTemperatureArray = newTemperatureArray.concat(e.data.values);
          } else{
            console.log('Temperature:', e.data.span);
            for(let j = 0; j < e.data.tempTime.length; j++){
              var tempTime = new Date(e.data.tempTime[j]);
              var tempEpochTime = tempTime.getTime();
              if(tempEpochTime >= firstTime && tempEpochTime <= lastTime){
                if(!firstFlag){
                  firstIndex = j;
                  firstFlag = true;
                }
                lastIndex = j;
              }
            }

            newTempTimeArray.splice.apply(newTempTimeArray, [firstIndex, (lastIndex - firstIndex) + 1].concat(e.data.timestamps));
            newTemperatureArray.splice.apply(newTemperatureArray, [firstIndex, (lastIndex - firstIndex) + 1].concat(e.data.values));
          }
        }

        var data = {dataType: e.data.signal, O2Timestamps: newO2TimestampArray, oxygen: newOxygenArray, tempTimestamps: newTempTimeArray, temperature: newTemperatureArray};
        postMessage(data);
    })
}

export default worker;
