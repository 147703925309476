import React from 'react';
import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";

export default function NewProbeReaderDialog(props) {

  return (
    <div>
      <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add A New Probe Reader</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You must add a new probe reader to your list before you can connect to it.
          </DialogContentText>
          <Formik
            initialValues={{ name: "", url: "", port: "9004" }}
            validate={values => {
              let errors = {};
              if (!values.name) {
                errors.name = "Name is required";
              } else if (!values.url) {
                errors.url = "A URL is required";
              }
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              props.onClose();
              props.saveConnectionToDatabase(values.name, values.url, values.port);

            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field
                  type="text"
                  name="name"
                  placeholder="Device Nickname"
                  fullWidth
                  component={TextField}
                />
                <Field
                  type="text"
                  name="url"
                  placeholder="IP Address"
                  fullWidth
                  component={TextField}
                />
                <DialogActions>
                  <Button onClick={props.onClose} color="secondary">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                            disabled={isSubmitting}
                        >
                            Create
                        </Button>
                    </DialogActions>
                </Form>
                )}
            </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}
