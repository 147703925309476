import React from 'react';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {tempLoginStates} from "../Defines.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import SignInButton from "./SignInButton.js";
import { withStyles } from '@material-ui/core/styles';
import { AosClasses } from '../../AosStyle';


export function TemporaryPermissionLogin(props) {
  const { classes } = props;
  const [values, setValues] = React.useState({
    expiry: '',
    enabled: true,
  });

  const handleChange = event => {
    setValues(
      () => (
        {
          [event.target.name]: event.target.value,
          enabled: event.target.name === undefined
        }
      ));
  };

  const requestClicked = () => {
    props.requestAdminPermission(values.expiry);
  }

  const requestForm = (
  <Grid
    container
    direction="column"
  >
    <Grid item>
      <Typography>
        Request temporary admin permission to edit device configuration.
      </Typography>
    </Grid>
    <Grid item>
      <FormControl className={classes.formControl} disabled={props.disabled}>
        <InputLabel htmlFor="expiry-select">Expiry</InputLabel>
        <Select
          value={values.expiry}
          onChange={handleChange}
          inputProps={{
              name: 'expiry',
              id: 'expiry-select'
          }}>
          <MenuItem value={3600}>1 hour</MenuItem>
          <MenuItem value={86400}>1 day</MenuItem>
          <MenuItem value={2592000}>30 days</MenuItem>
          <MenuItem value={-1}>Never</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid item>
      <Button
        onClick={requestClicked}
        variant="contained"
        color="primary"
        disabled={values.enabled || props.disabled}
      >Request
      </Button>
    </Grid>
    <Grid>
      {props.disabled ? (
        <Typography></Typography>
      ) : (
        <Typography color="error">{props.connectionMessage}</Typography>
      )}
    </Grid>
  </Grid>
)

const loginForm = (
<Grid
  container
  direction="column"
>
  <Grid item>
    <Typography>
      Sign in using stored browser permission.
    </Typography>
  </Grid>
  <Grid item>
    <Button
      color="secondary"
      onClick={props.forgetBrowserLogin}
      disabled={props.disabled}
    >
      Forget
    </Button>
      <SignInButton
        loginState={props.loginState}
        disabled={props.disabled}
        onClick={props.tempLogin}
        classes={props.classes}
      />
    </Grid>
  </Grid>
)

const requestPending = (
  <Grid container direction="column" alignItems="center">
    <Grid item>
      <CircularProgress variant="indeterminate" color="primary"/>
    </Grid>
    <Grid item>
      <Typography>Approve or deny the request on the device touchscreen interface.</Typography>
    </Grid>
  </Grid>
)

  switch(props.tempLoginState){
    case tempLoginStates.INITIAL:
      return requestForm;
    case tempLoginStates.TEMP_NO_AUTH:
      return requestForm;
    case tempLoginStates.TEMP_PENDING:
      return requestPending;
    case tempLoginStates.TEMP_AUTHED:
      return loginForm;
    default:
      break;
  }
}

export default withStyles(AosClasses)(TemporaryPermissionLogin);
