import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AosClasses }  from '../../AosStyle';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

function AutomaticTimeConfig (props) {

  var hours = [];
  for(let i = -14; i < 15; i++){
    hours.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
  }

  var minutes = [];
  for(let i = 0; i < 60; i += 5){
    minutes.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
  }

  const handleHoursChange = (event) => {
    props.publishConfigValue(
      "timeOffset",
      JSON.stringify(
        {
          hoursOffset: event.target.value,
          minutesOffset: props.minutesOffset,
        }
      )
    )
  }

  const handleMinutesChange = (event) => {
    props.publishConfigValue(
      "timeOffset",
      JSON.stringify(
        {
          hoursOffset: props.hoursOffset,
          minutesOffset: event.target.value,
        }
      )
    )
  }

  return (
    <Grid item container direction='row' spacing={2}>
      <Grid item container xs={12} spacing={1} alignItems='baseline'>
        <Grid item>
          <Typography>Automatic time</Typography>
        </Grid>
        <Grid item>
          <Typography><strong>{props.ntpStatus}</strong></Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={2} alignItems='center'>
        <Grid item>
          <Typography>Timezone offset</Typography>
        </Grid>
        <Grid item>
          <Select
            value={props.hoursOffset}
            onChange={handleHoursChange}
            disabled={props.loggingState}
          >
            {hours}
          </Select>
        </Grid>
        <Grid item><Typography>:</Typography></Grid>
        <Grid>
          <Select
            value={props.minutesOffset}
            onChange={handleMinutesChange}
            disabled={props.loggingState}
          >
            {minutes}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(AosClasses)(AutomaticTimeConfig);
