import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import InfoIcon from "@material-ui/icons/Info";
import StorageIcon from "@material-ui/icons/Storage";
import ColorizeIcon from "@material-ui/icons/Colorize";
import EventIcon from "@material-ui/icons/Event";
import SettingsInputHdmiIcon from "@material-ui/icons/SettingsInputHdmi";
import SystemUpdateIcon from "@material-ui/icons/SystemUpdate";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { Link } from "react-router-dom";
import { loginStates } from '../Defines.js';

export default function AnalyzerMenu(props) {

  const adminMenu = (
    <React.Fragment>
      <ListItem button key="chart" component={Link} to="/chart">
        <ListItemIcon>
          <ShowChartIcon />
        </ListItemIcon>
        <ListItemText primary="Live Data" />
      </ListItem>
      <ListItem button key="device" component={Link} to="/configuration">
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary="Configuration" />
      </ListItem>
      {(props.adminConnect !== loginStates.LOGGED_IN) ? null : (
        <List component="div" disablePadding>
          <ListItem
            button
            key="storage"
            component={Link}
            to="/configuration/storage"
            className={props.classes.nested}
          >
            <ListItemIcon>
              <StorageIcon />
            </ListItemIcon>
            <ListItemText primary="Storage" />
          </ListItem>
          <ListItem
            button
            key="sensors"
            component={Link}
            to="/configuration/sensors"
            className={props.classes.nested}
          >
            <ListItemIcon>
              <ColorizeIcon />
            </ListItemIcon>
            <ListItemText primary="Sensors" />
          </ListItem>
          <ListItem
            button
            key="date-time"
            component={Link}
            to="/configuration/date-time"
            className={props.classes.nested}
          >
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary="Date/Time" />
          </ListItem>
          <ListItem
            button
            key="network"
            component={Link}
            to="/configuration/network"
            className={props.classes.nested}
          >
            <ListItemIcon>
              <SettingsInputHdmiIcon />
            </ListItemIcon>
            <ListItemText primary="Network" />
          </ListItem>
          <ListItem
            button
            key="update"
            component={Link}
            to="/configuration/update"
            className={props.classes.nested}
          >
            <ListItemIcon>
              <SystemUpdateIcon />
            </ListItemIcon>
            <ListItemText primary="update" />
          </ListItem>
          <ListItem
            button
            key="users"
            component={Link}
            to="/configuration/users"
            className={props.classes.nested}
          >
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </List>
      )}
    </React.Fragment>
  );

  return props.mqttConnected ? adminMenu : null;
}
