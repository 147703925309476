import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import {loginStates} from "../Defines.js";
import { AosClasses } from '../../AosStyle';
import { withStyles } from '@material-ui/core/styles';


export function SignInButton (props)
{
  return (
    props.loginState === loginStates.LOGIN_PENDING && !props.disabled ? (
        <CircularProgress variant="indeterminate" color="primary"/>
    ):(
      <Button
        variant="contained"
        color="primary"
        className={props.classes.submit}
        disabled={props.disabled}
        onClick={props.onClick}
      >
        Sign In
      </Button>
    )
  )
}

export default withStyles(AosClasses)(SignInButton);
