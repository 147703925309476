import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { AosClasses } from "../../AosStyle";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

function NewUserDialog(props) {
  const [values, setValues] = React.useState({
    username: "",
    password: "",
    showPassword: false,
    buttonDisabled: true
  });

  const handleClickShowPassword = event => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleAddUserRequest = () => {
    props.publishConfigValue("add-user", JSON.stringify({
      username: values.username,
      password: values.password
    }));
    handleClear();
  };

  const handleClear = () => {
    setValues({ ...values, username: "", password: "", buttonDisabled: true });
  };

  const handleChange = prop => event => {
    let newLength = event.target.value.length;
    let otherLength =
      prop === "username" ? values.password.length : values.username.length;
    var buttonDisabled = newLength === 0 || otherLength === 0;
    setValues({
      ...values,
      [prop]: event.target.value,
      buttonDisabled: buttonDisabled
    });
  };

  return (
    <FormControl>
      <Grid container spacing={2} direction="row">
        <Grid item>
          <TextField
            label="Username"
            id="username"
            value={values.username}
            onChange={handleChange("username")}
          />
        </Grid>
        <Grid item>
          <TextField
            id="password1"
            label="Password"
            value={values.password}
            onChange={handleChange("password")}
            InputProps={{
              type: values.showPassword ? "text" : "password",
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <Button variant="contained" onClick={handleClear}>
                Clear
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddUserRequest}
                disabled={values.buttonDisabled}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default withStyles(AosClasses)(NewUserDialog);
