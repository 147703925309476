import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button"

export default function ConfigLogout(props) {

  return(
    <React.Fragment>
      <Typography>
        You are signed in with admin permissions to configure the device.
      </Typography>
      <Button
        variant="contained"
        color="primary"
      onClick={props.logout}
    >
      Sign out
    </Button>
    </React.Fragment>
  )
}
