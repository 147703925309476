import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import SignInButton from "./SignInButton.js";

export default function LoginForm(props) {

const[username, setUsernameValue] = React.useState("");
const handleUsernameChange = event => {
  setUsernameValue(event.target.value);
}

const [password, setPasswordValue] = React.useState("");
const handlePasswordChange = event => {
  setPasswordValue(event.target.value);
}

const onClick = () => {
  props.login(username, password);
  setPasswordValue("");
}

return(
  <form className={props.classes.form} noValidate="noValidate">
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="username"
      label="username"
      name="username"
      autoComplete="username"
      disabled={props.disabled}
      onChange={handleUsernameChange}
    />
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type="password"
      id="password"
      autoComplete="current-password"
      value={password}
      disabled={props.disabled}
      onChange={handlePasswordChange}
    />
    <SignInButton
      loginState={props.loginState}
      onClick={onClick}
      classes={props.classes}
      disabled={props.disabled}
    />
    {props.disabled ? (
      <Typography></Typography>
    ) :
      (<Typography color="error">{props.connectionMessage}</Typography>)}
  </form>
);
}
