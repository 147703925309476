import React from "react";
import { storiesOf } from "@storybook/react";
//import {action} from '@storybook/addon-actions';
import moment from "moment";
//import {Route} from 'react-router-dom';
import StoryRouter from "storybook-react-router";
import { muiTheme } from "storybook-addon-material-ui";
import { AosStyle } from "../../AosStyle";

import ConfigList from "./ConfigList";

export const readerConfig = {
  id: "Tim's test probe reader",
  thermocoupleType: "K",
  gasConfiguration: "Standard",
  reducingPolarity: "Positive",
  timeConfiguration: "Automatic",
  hoursOffset: 11,
  minutesOffset: 0,
  ntpStatus: "synchronized",
  storageState: "media mounted",
  storageSize: 14,
  storageUsed: 2,
  loggingResolution: 4,
  loggingState: "idle"
};

export const configProps = {
  connectedProbeReader: readerConfig,
  password: "this is not a password",
  passwordExpiry: moment.now()
};

const stories = storiesOf("Configuration", module);

stories.addDecorator(StoryRouter());
stories.addDecorator(muiTheme(AosStyle));

stories.add("default", () => <ConfigList {...configProps} />);
